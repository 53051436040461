<template>
  <div style="height: 100%; width: 100%; overflow: hidden">
    <div style="height: 100%" class="login">
      <img src="../../assets/image/beijing.jpg" alt="" style="width: 100%" />
      <div style="padding: 0 20px">
        <div class="login-part">
          <div v-if="$baseInfo.IS_CF == 0">
            <button class="nq-button" style="margin-top: 10px" @click="weChat">
              微信登录
            </button>
          </div>
          <div class="center-order">
            <PageTab
              style="height: calc(100vh - 200px)"
              :current="tabCurrent"
              :tabs="[
                {
                  label: '登录',
                  value: 1,
                },
                {
                  label: '注册',
                  value: 2,
                },
              ]"
              @change="tabChange"
            >
              <!-- 放第一栏内容 -->

              <div v-if="tabCurrent === 0">
                <nq-form
                  class="denglu"
                  ref="loginForm"
                  :formList="[
                    {
                      label: '会员名',
                      key: 'userName',
                      type: 'input',
                      placeholder: '会员名/邮箱',
                    },
                    {
                      label: '密码',
                      key: 'passWord',
                      type: 'input',
                      inputType: 'password',
                      placeholder: '密码',
                    },
                  ]"
                  :values="formValue"
                  @submit="submitForm"
                  @cancel="cancelForm"
                >
                </nq-form>
                <div class="bottom-part">
                  <button
                    v-loading="loginLoading"
                    class="nq-button denglv partOne"
                    @click="$refs.loginForm.submitForm()"
                  >
                    登录
                  </button>
                  <p style="font-size: 14px; margin-top: 10px" @click="forget">
                    忘记密码?
                  </p>
                </div>
              </div>
              <!-- 放第二栏内容 -->
              <div v-if="tabCurrent === 1">
                <nq-form
                  ref="registFormSubmit"
                  @submit="registFormSubmit"
                  @cancel="cancelRegistForm"
                  :values="formValue"
                  :formList="[
                    {
                      label: '用户名',
                      key: 'memberName',
                      type: 'input',
                      placeholder: '请输入用户名',
                    },
                    {
                      label: '邮箱',
                      key: 'mailBox',
                      type: 'input',
                      placeholder: '请输入邮箱',
                    },
                    {
                      label: '密码',
                      key: 'passwords',
                      type: 'input',
                      inputType: 'password',
                      placeholder: '请输入密码',
                    },
                    {
                      label: '确认密码',
                      key: 'surePassword',
                      type: 'input',
                      placeholder: '请再次输入密码',
                      validate: [
                        (formList, item, index) => {
                          return {
                            values: [formList[3], item],
                            validateRes: formList[3].value === item.value,
                            message: '两次密码不一致',
                          };
                        },
                      ],
                    },
                  ]"
                >
                </nq-form>
                <div class="bottom-part">
                  <p>
                    <input
                      type="checkbox"
                      v-model="agree"
                      style="margin-right: 10px"
                    /><span>我同意</span>
                    <span style="color: red" @click="speak">《免责声明》</span>
                  </p>
                  <p style="color: red">
                    {{ agreeTips }}
                  </p>
                  <button
                    class="nq-button zhuce"
                    @click="$refs.registFormSubmit.submitForm()"
                  >
                    注册
                  </button>
                  <p style="font-size: 14px">
                    已经注册，点击
                    <span style="color: red" @click="loginAgain">登录</span>
                  </p>
                </div>
              </div>
            </PageTab>
            <nqDialog
              :title="msgTitle"
              :visible="megVisible"
              @close="megVisible = false"
            >
              <div
                v-if="
                  msgTitle === '《' + $baseInfo.COMPANY_TITLE + '会员服务协议》'
                "
              >
                <div class="speak">
                  <p>
                    {{
                      $baseInfo.COMPANY_TITLE
                    }}向会员提供国际电子商务物流服务（以下简称“本服务”），并制定“{{
                      $baseInfo.COMPANY_TITLE
                    }}会员服务协议”（以下简称“本协议”）如下。
                  </p>
                  <p>
                    本服务协议内容包括协议正文及所有{{
                      $baseInfo.COMPANY_TITLE
                    }}已经发布的或将来可能发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。
                  </p>
                  <p>
                    用户在使用{{
                      $baseInfo.COMPANY_TITLE
                    }}提供的各项服务的同时，承诺接受并遵守各项相关规则的规定。{{
                      $baseInfo.COMPANY_TITLE
                    }}有权根据需要不时地制定、修改本协议或各类规则，如本协议有任何变更，{{
                      $baseInfo.COMPANY_TITLE
                    }}将在网站上刊载公告，通知予用户。如用户不同意相关变更，必须停止使用“本服务”。经修订的协议一经在{{
                      $baseInfo.COMPANY_TITLE
                    }}公布后，立即自动生效。各类规则会在发布后生效，亦成为本协议的一部分。登录或继续使用“本服务”将表示用户接受经修订的协议。除另行明确声明外，任何使“本服务”范围扩大或功能增强的新内容均受本协议约束。
                  </p>
                  <p>
                    用户确认本服务协议后，本服务协议即在用户和{{
                      $baseInfo.COMPANY_TITLE
                    }}之间产生法律效力。请用户务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向{{
                      $baseInfo.COMPANY_TITLE
                    }}咨询。无论用户事实上是否在注册之前认真阅读了本服务协议，只要用户点击协议正本下方的“确认”按钮并按照花瓣转运注册程序成功注册为用户，用户的行为仍然表示其同意并签署了本服务协议。
                  </p>
                  <h4>第一条（会员资格）</h4>
                  <p>
                    1.会员是指在同意本协议的基础上，在网上提交使用本公司运营服务的入会申请，经本公司批准成为会员后，收到会员ID的使用权.
                  </p>
                  <p>2.会员不得将会员资格出借、转让、买卖、抵押等。</p>
                  <h4>第二条（会员申请）</h4>
                  <p>
                    1.申请入会人员（以下简称“入会申请人”）需由本人网上提交注册申请。注册申请人为未成年人时，需经法定保护人同意后方可申请注册。前项的申请经本公司同意后即完成会员登录手续。但是如本公司发现入会申请人属于以下任何一项规定，可以拒绝申请人的入会申请，即使已批准入会者也可取消其会员资格。
                  </p>
                  <p>(a) 入会申请人为未成年人，且未经法定保护人同意；</p>
                  <p>(b)入会申请人曾经因违反本协议等行为而被取消会员资格；</p>
                  <p>(c)入会申请人提交的申请中，有虚假、误写或漏写的事项；</p>
                  <p>(d)入会申请人有未支付本公司债务的记录；</p>
                  <p>
                    (e)妨害本公司的运营和服务或妨害其他会员使用本公司的服务，或有阻碍以上运营和服务的行为；
                  </p>
                  <p>(f) 本公司认为不符合规定的其他情况。</p>
                  <h4>第三条（会员ID及密码的管理）</h4>
                  <p>
                    1.会员在进行会员注册后，须负责管理本公司的会员ID、密码。
                  </p>
                  <p>2.会员不得将会员ID及密码出借、转让、买卖、抵押。</p>
                  <p>
                    3.由于会员对持有的会员ID及密码的保管不当、使用上的过错等而造成的损失由该会员承担。
                  </p>
                  <p>
                    4.本公司不承担任何责任。使用会员ID和密码利用本服务的行为，即认为由会员本人进行的行为，会员对服务的使用承担一切责任。
                  </p>
                  <p>
                    5.当怀疑或得知会员ID或密码被第三方知悉及可能被非法使用时，应立即通知本公司，并按照本公司发出的指示执行。由于对会员ID及密码的不正当使用，而导致本公司发生损失时，该会员应赔偿本公司的损失。
                  </p>
                  <p>
                    6.会员有义务定期更改密码。因疏忽此项义务而造成的损失，本公司不承担任何责任。
                  </p>
                  <p>7.非公司特别认可，原则上不补发会员ID和密码。</p>
                  <h4>第四条（提交申请的内容变更）</h4>
                  <p>
                    1.会员入会申请时提交的注册内容发生变化时，应立即按规定格式提交变更申请。
                  </p>
                  <p>
                    2.本公司按照会员登录的联系方式发送公司的通知，并按常规到达的时间默认为送达。
                  </p>
                  <h4>
                    第五条（个人信息的处理） 本公司使用会员个人信息的目的如下。
                  </h4>
                  <p>
                    1.本公司根据前项的各种使用目的，可以共同使用公司持有的个人信息使用记录。如会员ID、姓名、性别、邮件地址、电话号码、邮政编码、地址、公司名称所属部门等、昵称、笔名、生日、信用卡信息、购物记录。
                  </p>
                  <p>
                    2.本公司遵循隐私政策保护个人信息，不向第三方提供具有可识别性的个人信息。
                  </p>
                  <h4>第六条（退会）</h4>
                  <p>1.会员可以按照本公司规定的手续办理退会手续。</p>
                  <p>
                    2.会员离世本公司将会员的离世视为退会时间，会员ID及密码将不能继续使用。
                  </p>
                  <h4>第七条（停止会员资格及除名）</h4>
                  <p>
                    本公司由于以下理由，可以在不通知或催告会员的情况下，暂时停止会员资格或除名。
                  </p>
                  <p>1.本人或指使他人不正当地使用会员ID、密码及本服务时；</p>
                  <p>
                    2.会员被执行没收、暂时没收、暂时处理、强制执行、破产、要求民事重整时，或会员自己提出请求时；
                  </p>
                  <p>3.密码输入错误次数高于本公司规定的次数时；</p>
                  <p>4.在本公司规定的期间内，没有使用本服务时；</p>
                  <p>5.其他，会员违反本协议或个别协议的任何条款时；</p>
                  <p>6.其他，本公司认为会员不符合要求时。</p>
                  <h4>第八条（本服务的内容)</h4>
                  <p>
                    本服务是国际电子商务物流的服务。由于不是本公司与销售公司达成商品的买卖关系，因此本公司不成为买卖合同的当事人。公司不承担一切与商品相关的瑕疵、侵害知识产权等责任。但，如果会员有证据证明商品的遗失、破损发生在本公司保管及运输期间内，则不受本条限制。
                  </p>
                  <h4>
                    第九条（本服务禁止使用的商品） 以下商品，不能使用本服务。
                  </h4>
                  <p>1.现金及支票、汇票、证券以及其他有价证券类；</p>
                  <p>2.信用卡、现金卡等卡类；</p>
                  <p>3.金融机关的存折以及现金提款卡；</p>
                  <p>
                    4.包裹上的送达地不易识别为本公司商号，也没有其他文字记载；
                  </p>
                  <p>5.活的动植物、死的动物或剥制的动物（即标本）；</p>
                  <p>6.人体或人体的一部分、遗体、遗骨、牌位；</p>
                  <p>
                    7.兴奋剂、大麻、麻药、作用于中枢神经影响精神状态的药物以及其他违禁药物；
                  </p>
                  <p>
                    8.枪炮、刀剑、武器、兵器、火药类、爆炸物、有毒物，有毒化学物品；
                  </p>
                  <p>9.医药品、医疗器械；</p>
                  <p>10.儿童色情画、成人色情录像带以及其他淫秽物品；</p>
                  <p>
                    11.其运送、进出口受包括经由国在内的进出口国、州、地方自治体的法令禁止或限制的物品；
                  </p>
                  <p>12.航空、运输公司的运输协议中不允许承运的其他物品；</p>
                  <p>13.本公司不予承运的物品。</p>
                  <p>
                    (i)
                    危险品液体类、喷雾类、气压类、刀具类货件，比如：打火机（不论是否含油）、指甲油、生发水、香水、带气球类、藏刀、野外用刀；
                  </p>
                  <p>(ii) 生物试剂类，比如：血浆、血清制品等；</p>
                  <p>(iii)古董类，比如古董字画、古董瓷器等；</p>
                  <p>
                    (iv)食品类，比如鲜肉、鱼、水果、蔬菜、海鲜干货（如海参、鱼翅等）等；
                  </p>
                  <p>
                    (v)其他类，比如烟、酒、象牙、犀牛角和（镶有象牙和犀牛角的货物）等。
                  </p>
                  <h4>第十条（本服务的价格和支付条件）</h4>
                  <p>
                    1.本服务费用如价格表所示。本公司可对价格表进行变更，恕不另行通知。计价依据的商品重量，以本公司的计量结果为准。
                  </p>
                  <p>
                    2.本服务费用包括从本公司运送到会员处的单程运输费。关税或发生从本公司到会员处的运输费以外的其他费用（以下简称为“特别费用”）时，由会员承担实际发生的费用。本公司没有义务垫付特别费用。
                  </p>
                  <p>
                    3.会员可通过支付宝、财付通、银行转账等方法支付我司的服务费用。
                  </p>
                  <h4>第十一条（订购商品的联系方法）</h4>
                  <p>
                    会员购入商品后，应立即按公司规定的方式，系统到货预报本公司接收商品所需的确切信息。
                  </p>
                  <h4>第十二条（验货）</h4>
                  <p>
                    1.本公司可以开封检查商品内容。但是，本公司并没有检验商品的义务，同时检验的结果也不对商品质量、有无瑕疵、真赝品做出保证，不保证商品不违反发出地、经由地、目的地所颁布的相关法令。
                  </p>
                  <p>
                    2.公司在进行前项规定的检验中，如果发现有违反“防止转移犯罪收益”相关的法律法令，怀疑有违反可能性的商品时，本公司可以采取报告警察及相关公署，提交商品等措施。
                  </p>
                  <p>
                    3.若检验商品及采取本条规定的处理办法造成会员损失时，本公司不承担任何责任。
                  </p>
                  <h4>第十三条（拒绝提供本服务）</h4>
                  <p>
                    如发生或可能发生以下情况，即使已经承接的业务也可拒绝提供本服务。
                  </p>
                  <p>1.属于第九条规定的商品时；</p>
                  <p>2.不能确认会员的所在地或商品发送目的地的地址时；</p>
                  <p>3.会员拒绝接收商品时；</p>
                  <p>4.会员拒绝支付服务费时；</p>
                  <p>5.运输公司不进行商品的运输时；</p>
                  <p>6.没有收到第十一条的通知时；</p>
                  <p>
                    7.根据第十一条的规定从会员处收到的有关商品信息的通知，与本公司收到的商品不相符时，或者第十一条的通知内容不正确时；
                  </p>
                  <p>8.被海关扣留时；</p>
                  <p>9.会员违反本协议时；</p>
                  <p>10.公司认为不符合规定的其他情况时。</p>
                  <h4>第十四条（不能转运商品的处理）</h4>
                  <p>
                    如发生或可能发生以下情况，即使已经承接的业务也可拒绝提供本服务。
                  </p>
                  <p>
                    1.本公司收到第九条规定的商品时，可以马上废弃或以其他方法处置。
                  </p>
                  <p>
                    2.当发生第十三条规定的情况（不包括13(1)）时，如果本公司已经接收了商品，则从接收日开始保管30天。
                  </p>
                  <p>
                    3.会员如果在本条第2项规定的期限内，解决了与第十三条规定相违的所有事由，可以要求本公司将商品发送到会员的地址或在本公司认可的方法中指定一种。
                  </p>
                  <p>
                    4.会员如果在本条第2项规定的期间内未能解决与第十三条规定相违的所有事由，本公司有权出售该商品或采取其他方法处置。
                  </p>
                  <p>
                    5.公司根据本条第4项规定出售商品后，商品销售收入可以充当保管以及处置商品所需费用、本服务的费用以及其他费用。
                  </p>
                  <p>
                    6.按本条规定处置商品时，即使会员受损失，本公司也不承担任何责任。
                  </p>
                  <p>
                    7.无论本条的规定如何，本公司均可以按民事执行法的规定办理。
                  </p>
                  <h4>第十五条（本服务的中断和停止）</h4>
                  <p>
                    发生以下任何情况时，本公司可以暂时中断或停止本服务的部分或全部内容，恕不另行通知。
                  </p>
                  <p>1.对提供本服务的装置和系统进行保养检查以及更新时；</p>
                  <p>2.遇到火灾、停电、天灾、系统故障等，难以提供本服务时；</p>
                  <p>3.电力通信公司未提供必需的服务时；</p>
                  <p>
                    4.本公司认为必须暂时中断或停止此项服务的其他情况；即使因暂时中断或停止提供本服务，而使会员遭受损失时，本公司也不承担任何责任。
                  </p>
                  <h4>第十六条（禁止行为）</h4>
                  <p>
                    会员在使用本服务中，不得有属于或有可能属于以下任何一项的行为。
                  </p>
                  <p>1.以不正当的目的使用本服务的行为；</p>
                  <p>
                    2.侵害本公司以及第三方的商标权、著作权、外观专利权、发明专利权等知识产权以及肖像权、广告宣传权以及其他权利；
                  </p>
                  <p>3.涉及欺诈等犯罪的行为；</p>
                  <p>4.伪装第三方使用本服务的行为；</p>
                  <p>
                    5.不正当地使用本公司以及第三方的设备，或阻碍运营的行为；
                  </p>
                  <p>
                    6.违反法令、本协议或个别协议，以及违反公共秩序和社会道德的行为；
                  </p>
                  <p>7.妨害本服务运营的行为；</p>
                  <p>8.公司认为不符合规定的其他行为。</p>
                  <p>
                    由于会员违反本协议或个别协议，使得本公司遭到损失时，本公司可以要求会员赔偿损失。
                  </p>
                  <h4>第十七条（适用范围和管辖）</h4>
                  <p>
                    本服务条款之解释与适用，以及与本服务条款有关的争议，均应依照中国法律予以处理.
                  </p>
                </div>
                <button class="nq-button" @click="megVisible = false">
                  关闭
                </button>
              </div>
              <div v-if="msgTitle === '微信登录'">
                <div>
                  <img src="../../assets/image/gzh.jpg" alt="" />
                  <div style="text-align: left; font-size: 16px; color: black">
                    截取此二维码并使用微信扫一扫，关注“牛气国际物流网”公众号，点击右下角下单服务--在线下单即可直接授权微信登录
                  </div>
                  <div>
                    <button class="nq-button" @click="megVisible = false">
                      关闭
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="msgTitle === '密码重置'">
                <nq-form
                  ref="loginForm"
                  @submit="submitForm"
                  @cancel="cancelForm"
                  :values="passWordList"
                  :formList="[
                    {
                      label: '会员名',
                      key: 'userName',
                      type: 'input',
                      placeholder: '会员名/邮箱',
                    },
                    {
                      label: '手机号',
                      key: 'telNum',
                      type: 'input',
                      placeholder: '手机号',
                    },
                  ]"
                >
                </nq-form>
                <div style="text-align: center">
                  <button class="nq-button">发送验证码</button>
                </div>
              </div>
            </nqDialog>
            <nqDialog
              title="公众号注册须知"
              :visible="loginVisible"
              @close="loginVisible = false"
            >
              <!-- 弹框一 -->
              <div class="yiqing">
                <p>
                  正常情况下，点击会员中心我们将自动注册登录，不会出现该界面，您可退出重试，请确认自己已经关注牛气公众号，点击会员中心后同意微信授权。
                </p>
                <p>如您不需要自动注册登录，也可点击下方按钮继续注册。</p>
              </div>
              <div style="text-align: center; margin-top: 30px">
                <button
                  class="nq-button guanbi-button"
                  style="padding: 8px 20px; margin-right: 30px"
                  @click="loginVisible = false"
                >
                  取消
                </button>
                <button
                  class="nq-button copy-but"
                  @click="loginVisible = false"
                  style="padding: 8px 20px"
                >
                  继续注册
                </button>
              </div>
            </nqDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { htmlLoginAndRegist } from '../../utils/apis/apis'
export default {
  name: 'login',
  data() {
    return {
      loginVisible: false,
      agree: false,
      tabCurrent: 0,
      tab1PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab2PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      msgTitle: '',
      megVisible: false,
      loginLoading: false,
      agreeTips: '',
      formValue: {
        mailbox: '',
        userName: '',
        telPhone: '',
        password: '',
        surePassword: ''
      },
      passWordList: {
        mailbox: '',
        telNum: ''
      },
      firstCode: ''
    }
  },
  created() {
    this.loginVisible = true
  },
  mounted() {},
  methods: {
    // 登录提交
    submitForm(params) {
      this.loginLoading = true
      this.$reqPost(htmlLoginAndRegist.login, params).then((res) => {
        this.loginLoading = false
        if (res.data.code === 200) {
          console.log(res)
          localStorage.userInfo = JSON.stringify(res.data.data)
          this.$router.push('/user-center')
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    // 登录取消
    cancelForm() {},
    // 提交注册
    registFormSubmit(params) {
      this.agreeTips = ''
      if (!this.agree) {
        this.agreeTips = '请勾选并阅读免责声明'
        return
      }

      this.$reqPost(htmlLoginAndRegist.regist, params).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success('注册成功')
          location.reload()
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    // 取消注册
    cancelRegistForm() {},
    speak() {
      this.msgTitle = '《牛气物流会员服务协议》'
      this.megVisible = true
    },
    weChat() {
      this.msgTitle = '微信登录'
      this.megVisible = true
    },
    tabChange(index, item) {
      this.tabCurrent = index
      // console.log(this.tabCurrent);
    },
    loginAgain() {
      this.tabCurrent = 0
    },
    forget() {
      this.msgTitle = '密码重置'
      this.megVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
.login {
  position: relative;
}
.login-part {
  position: absolute;
  top: 10%;
  // left: 5%;
  height: 88%;
  width: 90%;
  padding: 8px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgb(209, 209, 209), 0 0 1px rgb(209, 209, 209),
    0 0 1px rgb(209, 209, 209);
}
/deep/ .tab-handler {
  padding: 0 70px;
}
/deep/ .denglu .form-item-label {
  margin-top: 20px;
}
/deep/ .form-item-label {
  text-align: left;
  margin-top: 10px;
}
/deep/ .error-text {
  height: 0 !important;
}
.bottom-part {
  // position: absolute;
  left: 16%;
  margin-top: 20px;
}
.bottom-part p {
  margin-bottom: 30px;
}
.speak {
  text-align: left;
  font-size: 14px;
  line-height: 22px;
}
// /deep/ .close-icon img {
//   display: none;
// }
/deep/ .nq-button {
  margin: 20px 0;
}
/deep/ .bottom-text {
  display: none;
}
.denglv {
  width: 240px;
  margin-top: 50px;
}
.zhuce {
  width: 240px;
}
// /deep/ .page-main {
//  display: none !important;
// }
.yiqing {
  text-align: left;
  font-size: 15px;
  line-height: 22px;
}
</style>
